import { pick } from 'lodash';

import { TextInput } from '../components/form/text-input';
import { TextEditor } from '../components/form/text-editor';
import { MultiSelect } from '../components/form/multi-select';

export const Service = {
    editableFields: ['name', 'price', 'skills', 'description'],
    fields: {
        name: TextInput,
        price: TextInput,
        skills: MultiSelect,
        description: TextEditor,
    },
    validations: {
        name: {
            required: { presence: true },
            length: { max: 80 },
        },
        price: {
            required: { presence: true },
            length: { max: 80 },
        },
        skills: {
            length: { max: 20 },
        },
        description: {
            required: { presence: true },
            length: { min: 10 },
        },
    },
    required: {
        name: true,
        price: true,
        skills: false,
        description: true,
    },
    labels: {
        name: 'Name',
        price: 'Pricing',
        skills: 'Skills',
        description: 'Description',
    },
    placeholders: {
        name: 'Name of your service',
        price: 'Price information',
        skills: 'Type a skill and press enter to add',
        description: 'Describe the service',
    },

    helpText: {
        name: 'Name of your service',
        price: 'Add pricing information here.',
        skills:
            'Which skills are you offering with this service? You can add multiple skills. Start typing the name of the skill and then press enter to add it to the list. You can also paste comma separated lists of skills.',
        description:
            'Describe your offering in detail. You can use some rich text formatting here like headers, lists, links etc by selecting text and changing the text style with the balloon editor.',
    },
    new: function(userId, data) {
        return {
            ...pick(data, Service.editableFields),
            userId,
        };
    },
};
