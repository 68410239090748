import { isLoggedIn } from "../../utils/firebase";

export function currentUserProjectsSet(state, payload) {
    if (!isLoggedIn()) {
        return state;
    }

    state.currentUser.projects = payload;

    return state;
}
