import React, { useContext } from 'react';

import { get } from 'lodash';
import classNames from 'classnames';

import { Label } from './label';

import { FormContext } from './form';
import { FieldErrorText } from '../field-error-text';

export const TextInput = ({ path, required, label, errorLabel, helpText, ...rest }) => {
    const { state, errors, onChange, onBlur, getValidationClasses } = useContext(FormContext);

    const value = get(state, path, '');
    const fieldErrors = get(errors, path);

    const validationClasses = getValidationClasses(path);
    const fieldClasses = classNames('field', validationClasses);
    const inputClasses = classNames('input', validationClasses);

    return (
        <div className={fieldClasses}>
            <Label htmlFor={path} required={required} helpText={helpText}>
                {label}
            </Label>
            <input
                className={inputClasses}
                name={path}
                id={path}
                required={required}
                value={value}
                onChange={e => {
                    onChange(path, e.target.value);
                }}
                onBlur={e => {
                    onBlur(path, e.target.value);
                }}
                {...rest}
            />
            <FieldErrorText errors={fieldErrors} errorLabel={errorLabel || label} />
        </div>
    );
};
