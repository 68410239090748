import { pick } from 'lodash';

import { TextInput } from '../components/form/text-input';
import { AvailabilitySelector } from '../components/availabilty-selector';
import { MultiSelect } from '../components/form/multi-select';
import { TextEditor } from '../components/form/text-editor';

export const Profile = {
    editableFields: ['about', 'availability', 'blurb', 'handle', 'locations'],
    fields: {
        about: TextEditor,
        availability: AvailabilitySelector,
        blurb: TextInput,
        handle: TextInput,
        locations: MultiSelect,
    },
    validations: {
        availability: {
            required: { presence: true },
        },
        blurb: {
            required: { presence: true },
            length: { max: 40 },
        },
        handle: {
            required: { presence: true },
            length: { min: 3, max: 50 },
        },
        locations: {
            required: { presence: true },
            length: { max: 20 },
        },
    },
    required: {
        about: false,
        availability: true,
        blurb: true,
        handle: true,
        locations: true,
    },
    labels: {
        about: 'About Me',
        availability: 'Your availability',
        blurb: 'Short description',
        handle: 'Handle',
        locations: 'Locations',
    },
    placeholders: {
        about: 'Introduce yourself',
        blurb: 'Short description',
        handle: 'Your handle',
        locations: 'Type a location and press enter to add',
    },
    helpText: {
        about:
            'Introduce yourself. You can use some rich text formatting here like headers, lists, links etc by selecting text and changing the text style with the balloon editor.',
        availability: 'Show your availability status for new projects.',
        blurb: 'This will be displayed directly beneath your handle on your profile page. Use it to describe yourself in a few words.',
        handle:
            "Your public profile can be accessed by using your handle. It may consist of alpha-numerical characters with . - _ in between. i.e.: '-foo-' is not allowed, but 'foo.bar' or 'foo-bar'",
        locations:
            "In which locations you are available? You can add multiple locations or use 'Any' or 'Remote' if you're flexible. Start typing the name of the location and then press enter to add it to the list. You can also paste comma separated lists of locations.",
    },
    new: function(userId, data) {
        return {
            ...pick(data, Profile.editableFields),
            userId,
        };
    },
};
