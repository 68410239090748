import React, { createContext, useReducer } from 'react';
import merge from 'lodash/merge';

import { ApplicationReducer } from './reducer';

import { firebase } from '../utils/firebase';

export const ApplicationContext = createContext({ state: {}, dispatch: null, firebaseUiConfig: {} });

export const defaultState = {
    currentUser: undefined,
    modal: undefined,
    modalContext: undefined,
    listeners: [],
};

export const firebaseUiConfig = {
    signInFlow: 'popup',
    callbacks: {
        signInSuccessWithAuthResult: () => {},
    },
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.EmailAuthProvider.PROVIDER_ID, firebase.auth.GithubAuthProvider.PROVIDER_ID],
};

export const ApplicationContextProvider = ({ initialState = {}, children }) => {
    const initialProps = merge(defaultState, initialState);

    const [state, dispatch] = useReducer(ApplicationReducer, initialProps);
    const value = { state, dispatch, firebaseUiConfig };

    return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>;
};
