import { addUidToDocument, db } from '../utils/firebase';
import { pick } from 'lodash';

import { Project } from '../models/project';
import { Profile } from '../models/profile';
import { Service } from '../models/service';
import { Skill } from '../models/skill';
import { Endorsement } from '../models/endorsement';

function userDoc(userId) {
    return db.collection('users').doc(userId);
}

function userSkillsCollection(userId) {
    return db.collection('skills').where('userId', '==', userId);
}

function userProjectsCollection(userId) {
    return userDoc(userId).collection('projects');
}

export const Repository = {
    profile: {
        findByHandle: async function(handle) {
            const docs = await db
                .collection('profiles')
                .where('handle', '==', handle)
                .limit(1)
                .get();

            if (docs.empty) {
                return null;
            }

            return docs.empty ? null : addUidToDocument(docs.docs[0]);
        },
        skills: async function(userId) {
            const docs = await userSkillsCollection(userId)
                .orderBy('name')
                .get();

            return docs.empty ? null : docs.docs.map(addUidToDocument);
        },
        projects: async function(userId) {
            const docs = await userProjectsCollection(userId)
                .orderBy('start', 'desc')
                .get();

            return docs.empty ? null : docs.docs.map(addUidToDocument);
        },
        services: async function(userId) {
            const docs = await db
                .collection('services')
                .where('userId', '==', userId)
                .get();

            return docs.empty ? null : docs.docs.map(addUidToDocument);
        },
        endorsements: async function(userId) {
            const docs = await db
                .collection('endorsements')
                .where('resourceOwnerId', '==', userId)
                .get();

            return docs.empty ? null : docs.docs.map(addUidToDocument);
        },
        fullProfileByHandle: async function(handle) {
            const profile = await Repository.profile.findByHandle(handle);

            if (!profile) {
                return null;
            }

            const [skills, projects, services, endorsements] = await Promise.all([
                Repository.profile.skills(profile.uid),
                Repository.profile.projects(profile.uid),
                Repository.profile.services(profile.uid),
                Repository.profile.endorsements(profile.uid),
            ]);

            return {
                ...profile,
                skills,
                projects,
                services,
                endorsements,
            };
        },
        update: async function(userId, profile) {
            const docRef = await db.collection('profiles').doc(userId);
            const doc = await docRef.get();
            const exists = doc.exists;

            const newProfile = Profile.new(userId, profile);

            if (exists) {
                return docRef.update(newProfile);
            } else {
                return docRef.set(newProfile);
            }
        },
    },
    user: {
        find: function(userId, callback) {
            return userDoc(userId).onSnapshot(doc => callback(addUidToDocument(doc)));
        },
        profile: function(userId, callback) {
            return db
                .collection('profiles')
                .doc(userId)
                .onSnapshot(doc => callback(addUidToDocument(doc)));
        },
        skills: function(userId, callback) {
            return userSkillsCollection(userId)
                .orderBy('name')
                .onSnapshot(docs => callback(docs.docs.map(addUidToDocument)));
        },
        projects: function(userId, callback) {
            return userProjectsCollection(userId)
                .orderBy('start', 'desc')
                .onSnapshot(docs => callback(docs.docs.map(addUidToDocument)));
        },
        services: function(userId, callback) {
            return db
                .collection('services')
                .where('userId', '==', userId)
                .onSnapshot(docs => callback(docs.docs.map(addUidToDocument)));
        },
        endorsements: function(userId, callback) {
            return db
                .collection('endorsements')
                .where('resourceOwnerId', '==', userId)
                .onSnapshot(docs => callback(docs.docs.map(addUidToDocument)));
        },
    },
    skills: {
        add: function(userId, skill) {
            const newSkill = Skill.new(userId, skill);
            const skillId = Skill.buildSkillId(userId, skill);

            return db
                .collection('skills')
                .doc(skillId)
                .set(newSkill)
                .then(() => console.log('successfully added skill'))
                .catch(err => console.error(err));
        },
        delete: function(skillId) {
            return db
                .collection('skills')
                .doc(skillId)
                .delete()
                .then(() => console.log('successfully deleted skill'))
                .catch(err => console.error(err));
        },
    },
    endorsements: {
        add: function(endorsementData) {
            const newEndorsement = Endorsement.new(endorsementData);
            return db
                .collection('endorsements')
                .add(newEndorsement)
                .then(() => console.log('successfully added endorsement'))
                .catch(err => console.error(err));
        },
        list: function(resourceType, resourceId, callback) {
            return db
                .collection('endorsements')
                .where('resourceType', '==', resourceType)
                .where('resourceId', '==', resourceId)
                .onSnapshot(docs => callback(docs.docs.map(addUidToDocument)));
        },
    },
    projects: {
        add: function(userId, project) {
            const newProject = Project.new(userId, project);

            return userProjectsCollection(userId)
                .add(newProject)
                .then(() => console.log('successfully added project'))
                .catch(err => console.error(err));
        },
        update: async function(userId, projectId, project) {
            delete project.userId;
            delete project.uid;
            const docRef = await userProjectsCollection(userId).doc(projectId);
            const doc = await docRef.get();
            const exists = doc.exists;

            const newProject = {
                ...pick(project, Project.editableFields),
                userId,
            };

            if (exists) {
                docRef.update(newProject);
            }
        },
        delete: function(userId, projectId) {
            return userProjectsCollection(userId)
                .doc(projectId)
                .delete()
                .then(() => console.log('successfully deleted project'))
                .catch(err => console.error(err));
        },
    },
    services: {
        add: function(userId, service) {
            const newService = Service.new(userId, service);

            db.collection('services')
                .add(newService)
                .then(() => console.log('successfully added service'))
                .catch(err => console.error(err));
        },
        update: async function(userId, serviceId, service) {
            delete service.userId;
            delete service.uid;
            const docRef = await db.collection('services').doc(serviceId);
            const doc = await docRef.get();
            const exists = doc.exists;

            const newService = {
                ...pick(service, Service.editableFields),
                userId,
            };

            if (exists) {
                docRef.update(newService);
            }
        },
        delete: function(userId, serviceId) {
            return db
                .collection('services')
                .doc(serviceId)
                .delete()
                .then(() => console.log('successfully deleted service'))
                .catch(err => console.error(err));
        },
    },
};
