import React from 'react';

import classNames from 'classnames';

import { Icon } from './icon';

export const TagIconPosition = {
    before: 'before',
    after: 'after',
};

export const Tag = ({ children, icon, iconPosition = TagIconPosition.after, onIconClick, className }) => {
    const hasIcon = Boolean(icon);
    const hasButton = Boolean(onIconClick);

    if (!hasIcon && !hasButton) {
        return <SimpleTag className={className}>{children}</SimpleTag>;
    }

    if (hasIcon && !hasButton) {
        return (
            <IconTag className={className} icon={icon} iconPosition={iconPosition}>
                {children}
            </IconTag>
        );
    }

    return (
        <ButtonIconTag className={className} iconPosition={iconPosition} icon={icon} onIconClick={onIconClick}>
            {children}
        </ButtonIconTag>
    );
};

export const ButtonIconTag = ({ children, icon, iconPosition = TagIconPosition.after, onIconClick, className }) => {
    const classes = classNames('tag', 'has-addons', className);

    const buttonElement = (
        <span className="tag has-icon">
            <button className="is-link" onClick={onIconClick}>
                <Icon icon={icon} />
            </button>
        </span>
    );

    return (
        <span className={classes}>
            {iconPosition === TagIconPosition.before && buttonElement}
            <SimpleTag>{children}</SimpleTag>
            {iconPosition === TagIconPosition.after && buttonElement}
        </span>
    );
};

export const IconTag = ({ children, icon, iconPosition = TagIconPosition.after, className }) => {
    const classes = classNames('tag', 'has-addons', className);

    const iconElement = (
        <span className="tag has-icon">
            <Icon icon={icon} />
        </span>
    );

    return (
        <span className={classes}>
            {iconPosition === TagIconPosition.before && iconElement}
            <SimpleTag>{children}</SimpleTag>
            {iconPosition === TagIconPosition.after && iconElement}
        </span>
    );
};

export const SimpleTag = ({ children, className }) => {
    const classes = classNames('tag', className);

    return <span className={classes}>{children}</span>;
};
