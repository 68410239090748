import React from 'react';

import { Route, Switch } from 'react-router';
import loadable from '@loadable/component';

import { isLoggedIn } from './utils/firebase';
import { LoginNotice } from './components/login-notice';

import { Sitemap } from './sitemap';
import { Spinner } from './components/loading-indicator';

import { asyncProfileLoad } from './application/actions/profile-load';

const PublicProfilePage = loadable(() => import('./pages/public-profile-page'), { fallback: <Spinner /> });
const HomePage = loadable(() => import('./pages/homepage'), { fallback: <Spinner /> });
const LoginPage = loadable(() => import('./pages/login-page'), { fallback: <Spinner /> });
const MyProfilePage = loadable(() => import('./pages/my-profile-page/my-profile-page'), { fallback: <Spinner /> });

export const AuthenticatedRoute = ({ component: Component, permissions = {}, ...props }) => {
    return (
        <Route
            {...props}
            render={props => {
                if (isLoggedIn()) {
                    return <Component {...props} />;
                } else {
                    return <LoginNotice />;
                }
            }}
        />
    );
};

export const PageNotFound = () => {
    return <h1>404 Page not found</h1>;
};

export const SitemapConfig = {
    '/u/:handle': {
        id: 'public-profile-page',
        component: PublicProfilePage,
        routeProps: { exact: true },
        prefetch: async params => {
            return await asyncProfileLoad({}, params.handle);
        },
    },
    '/login': {
        id: 'login-page',
        component: LoginPage,
        routeProps: { exact: true },
    },
    '/my-profile': {
        id: 'my-profile-page',
        component: MyProfilePage,
        routeComponent: AuthenticatedRoute,
        routeProps: { exact: true },
    },
    '/': {
        id: 'home-page',
        component: HomePage,
        routeProps: { exact: true },
    },
    '*': {
        id: 'catch-all',
        component: PageNotFound,
    },
};

export const SkillsSitemap = new Sitemap(SitemapConfig);

export const SkillsSitemapComponent = props => {
    const routes = SkillsSitemap.pages.map(({ routeComponent, routeProps, component, route, id }) => {
        const Component = routeComponent || Route;

        return <Component {...(routeProps || {})} component={component} path={route} key={id} />;
    });

    return <Switch>{routes}</Switch>;
};
