import React from 'react';

import { Select } from './form/select';

export const AvailabilitySelector = ({ path, label, helpText, required }) => {
    return (
        <div className="availability-selector">
            <Select path={path} label={label} helpText={helpText} required={required}>
                <option key="blank" value="">
                    Select your availability
                </option>
                <option value="available">Available</option>
                <option value="unavailable">Not available</option>
            </Select>
        </div>
    );
};
