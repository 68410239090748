import React, { Fragment, useState } from 'react';

import classNames from 'classnames';
import { Icon } from '../icon';

export const Label = ({ children, required, className, helpText, ...rest }) => {
    const [showHelp, setShowHelp] = useState(false);

    if (!children) {
        return null;
    }

    function handleToggleClick(e) {
        e.preventDefault();
        e.stopPropagation();

        setShowHelp(!showHelp);
    }

    const classes = classNames('label', className);

    return (
        <Fragment>
            <div className="label-wrapper">
                <label {...rest} className={classes}>
                    {children}
                    {required && <span className="is-required">*</span>}
                </label>
                {helpText && (
                    <button className="helper-text-toggle is-link" title="Show help text" onClick={handleToggleClick}>
                        <Icon icon={showHelp ? 'fa fa-question-circle' : 'fa fa-question-circle'} />
                    </button>
                )}
            </div>
            {helpText && showHelp && <p className="help">{helpText}</p>}
        </Fragment>
    );
};
