import React from 'react';

import { ApplicationContextProvider } from './application/context';

import { setupEnv } from './utils/axios';
import { ApplicationContent } from './layout/application-content';

setupEnv();

export const App = ({ initialState }) => (
    <ApplicationContextProvider initialState={initialState}>
        <ApplicationContent />
    </ApplicationContextProvider>
);
