import axios from "axios";

import { getEnv } from "./env";

const allowedOrigins = {
    production: 'https://europe-west1-skills-56939.cloudfunctions.net',
};

// const baseUrls = {
//     production: 'https://europe-west1-skills-56939.cloudfunctions.net',
//     development: 'http://localhost:5000/skills-56939/europe-west1',
// };

// function functionPath(path) {
//     const baseUrl = baseUrls[getEnv()];
//
//     return baseUrl ? [baseUrl, path].join('') : path;
// }

export function setupEnv() {
    const allowedOrigin = allowedOrigins[getEnv()];

    if (allowedOrigin) {
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = allowedOrigin;
    }

}


// function axiosGet(path) {
//     return axios.get(functionPath(path));
// }

// function helloCloudFunction(setGreeting) {
//     axiosGet('/helloWorld')
//         .then(({ data }) => setGreeting(data.hello))
//         .catch(err => console.error(err));
// }