import React, { useContext } from 'react';
import classNames from 'classnames';
import { get } from 'lodash';

import loadable from '@loadable/component';

import { FieldErrorText } from '../field-error-text';
import { FormContext } from './form';
import { Label } from './label';
import { Spinner } from '../loading-indicator';

const Editor = loadable(() => import('../editor'), { fallback: <Spinner /> });

const ckConfig = {};

export const TextEditor = ({ path, placeholder, label, errorLabel, helpText, required }) => {
    const { state, errors, onChange, onBlur, getValidationClasses } = useContext(FormContext);
    const value = get(state, path, '');

    const config = {
        ...ckConfig,
        placeholder,
    };

    const fieldErrors = get(errors, path);

    const validationClasses = getValidationClasses(path);
    const fieldClasses = classNames('field', validationClasses);

    return (
        <div className={fieldClasses}>
            <Label htmlFor={path} required={required} helpText={helpText}>
                {label}
            </Label>

            <Editor
                config={config}
                data={value}
                onChange={(event, editor) => {
                    onChange(path, editor.getData());
                }}
                onBlur={(event, editor) => {
                    onBlur(path, editor.getData());
                }}
            />

            <FieldErrorText errors={fieldErrors} errorLabel={errorLabel || label} />
        </div>
    );
};
