import { Actions } from '../actions';
import { Repository } from '../repository';

export function currentUserProfileLoad(state, payload) {
    const { currentUser, listeners } = state;
    if (!currentUser) {
        return state;
    }

    const { user: uid, dispatch } = payload;

    if (!uid) {
        return state;
    }

    // setup live updates:

    listeners.push(Repository.user.find(uid, user => dispatch({ type: Actions.currentUser.profile.set, payload: user })));

    listeners.push(Repository.user.profile(uid, profile => dispatch({ type: Actions.currentUser.publicProfile.set, payload: profile })));

    listeners.push(
        Repository.user.skills(uid, skills => {
            dispatch({
                type: Actions.currentUser.skills.set,
                payload: skills,
            });
        })
    );

    listeners.push(
        Repository.user.projects(uid, projects => {
            dispatch({
                type: Actions.currentUser.projects.set,
                payload: projects,
            });
        })
    );

    listeners.push(
        Repository.user.services(uid, services => {
            dispatch({
                type: Actions.currentUser.services.set,
                payload: services,
            });
        })
    );

    listeners.push(
        Repository.user.endorsements(uid, endorsements => {
            dispatch({
                type: Actions.currentUser.endorsements.set,
                payload: endorsements,
            });
        })
    );

    return state;
}
