import React, { useState, useEffect, createContext } from 'react';

import { get, set, cloneDeep } from 'lodash';
import classNames from 'classnames';

import { trimObject } from '../../utils/trim-object';
import { isObjectValid, validate } from '../../utils/validations/validations';
import { hasObjectValues } from '../../utils/validations/helpers';

export const FormContext = createContext({
    state: {},
    touched: {},
    errors: {},
    markAsTouched: null,
    getValidatonState: null,
    getValidationClasses: null,
    isFormValid: null,
    onChange: null,
    onBlur: null,
});

export const Form = ({ className, onChange, onSubmit, children, initialState = {}, validations = {}, converters = {}, ...rest }) => {
    const [formData, setFormData] = useState(initialState);
    const [touched, setTouched] = useState({});
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        const formValid = isObjectValid(formData, validations);
        setIsFormValid(formValid);
    }, [formData, validations]);

    const classes = classNames('form', className);

    function getValidationState(path) {
        const fieldTouched = get(touched, path, false);

        // if the field was not touched yet, we don't need to display any validation state:
        if (!fieldTouched) {
            return { showErrors: false, showValid: false };
        }

        const fieldErrors = get(errors, path);
        const hasErrors = hasObjectValues(fieldErrors);

        return { showErrors: hasErrors, showValid: !hasErrors };
    }

    function getValidationClasses(path){
        const {showErrors, showValid } = getValidationState(path);
        return classNames({
            'is-danger': showErrors,
            'is-success': showValid,
        });
    }

    function validateField(path, formData, touched) {
        const validationConfig = get(validations, path);
        if (!validationConfig) {
            return null;
        }

        const isTouched = get(touched, path);
        if (!isTouched) {
            return null;
        }

        const value = get(formData, path);
        const validationResult = validate(value, validationConfig);

        const newErrors = cloneDeep(errors);
        set(newErrors, path, hasObjectValues(validationResult) ? validationResult : null);

        setErrors(newErrors);
    }

    function markAsTouched(path) {
        const newTouched = cloneDeep(touched);
        set(newTouched, path, true);
        setTouched(newTouched);
    }

    function convert(path, value) {
        const converter = get(converters, path);
        if (!converter) {
            return value;
        }
        return converter(value);
    }

    function handleFieldChange(path, value) {
        const newTouched = cloneDeep(touched);

        set(newTouched, path, true);
        setTouched(newTouched);

        const newFormData = cloneDeep(formData);
        set(newFormData, path, convert(path, value));
        setFormData(newFormData);

        validateField(path, newFormData, newTouched);
    }

    function handleFieldBlur(path) {
        validateField(path, formData, touched);
    }

    function handleFormSubmit(e) {
        e.preventDefault();

        onSubmit(trimObject(formData));

        setTouched({});
        setFormData({});
    }

    const contextValues = {
        state: cloneDeep(formData),
        touched: cloneDeep(touched),
        errors: cloneDeep(errors),
        isFormValid,
        markAsTouched,
        getValidationClasses,
        getValidationState,
        onChange: handleFieldChange,
        onBlur: handleFieldBlur,
    };

    return (
        <FormContext.Provider value={contextValues}>
            <form {...rest} className={classes} onSubmit={handleFormSubmit}>
                {children}
            </form>
        </FormContext.Provider>
    );
};
