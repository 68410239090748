import { get } from 'lodash';

import { Repository } from '../repository';
import { isLoggedIn } from "../../utils/firebase";

export function isDataValid(state, payload) {
    return isLoggedIn() && get(payload, 'uid');
}

export function currentUserProjectsDelete(state, payload) {
    if (!isDataValid(state, payload)) {
        return state;
    }

    const { currentUser } = state;

    Repository.projects.delete(currentUser.uid, payload.uid);

    return state;
}
