import React from 'react';

import { Link } from "react-router-dom";

import { Icon } from "../../components/icon";

import { logoutUser } from "../../utils/firebase";

export const Authentication = ({ currentUser }) => {
    function handleSignOut(e) {
        e.preventDefault();
        logoutUser();
    }

    return (
        <div className="navigation-authentication">
            {currentUser ? (
                <button className="is-link" onClick={handleSignOut} title="sign out">
                    <Icon icon="fa fa-sign-out" />
                </button>
            ) : (
                <Link to="/login" title="sign in / sign up">
                    <Icon icon="fa fa-sign-in" />
                </Link>
            )}
        </div>
    );
};