export function currentUserLogout(state) {
    state.currentUser = undefined;

    // unregister listeners:
    while(state.listeners.length > 0){
        const listener = state.listeners.shift();
        listener();
    }

    return state;
}
