import { get } from 'lodash';

import { Repository } from '../repository';
import { isLoggedIn } from "../../utils/firebase";

export function hasSkill(name, skills) {
    return skills.find(skill => name.toLowerCase() === skill.name.toLowerCase());
}

export function isDataValid(state, payload) {
    return isLoggedIn() && get(payload, 'name');
}

export function currentUserSkillsAdd(state, payload) {
    if (!isDataValid(state, payload)) {
        return state;
    }

    const { currentUser } = state;

    if (hasSkill(payload.name, currentUser.skills || [])) {
        console.error('skill already present');
        return state;
    }

    Repository.skills.add(currentUser.uid, payload);

    return state;
}
