import { get } from 'lodash';

import { Repository } from '../repository';
import { isLoggedIn } from '../../utils/firebase';

export function isDataValid(state, payload) {
    return isLoggedIn() && get(payload, 'uid');
}

export function currentUserSkillsDelete(state, payload) {
    if (!isDataValid(state, payload)) {
        return state;
    }

    Repository.skills.delete(payload.uid);

    return state;
}
