export const ENV = {
    production: 'production',
    development: 'development',
};

export function inBrowser() {
    return typeof window !== 'undefined';
}

export function getEnv(location = inBrowser() ? window.location : null) {
    if (location) {
        return location.hostname.match('skills') ? ENV.production : ENV.development;
    }

    return ENV[process.env['NODE_ENV']] || ENV.production;
}
