import { pick } from 'lodash';

import { TextInput } from '../components/form/text-input';
import { MultiSelect } from '../components/form/multi-select';
import { TextEditor } from '../components/form/text-editor';
import { Select } from '../components/form/select';
import { DatePicker } from '../components/form/datepicker';

export const Project = {
    editableFields: ['name', 'location', 'role', 'type', 'start', 'end', 'skills', 'description'],
    fields: {
        name: TextInput,
        location: MultiSelect,
        role: TextInput,
        type: Select,
        start: DatePicker,
        end: DatePicker,
        skills: MultiSelect,
        description: TextEditor,
    },
    validations: {
        name: {
            required: { presence: true },
            length: { max: 60 },
        },
        role: {
            required: { presence: true },
            length: { max: 80 },
        },
        type: {
            required: { presence: true },
        },
        skills: {
            required: { presence: true },
            length: { max: 20 },
        },
        description: {
            required: { presence: true },
            length: { min: 10 },
        },
    },
    required: {
        name: true,
        location: false,
        role: true,
        type: true,
        start: false,
        end: false,
        skills: true,
        description: true,
    },
    labels: {
        name: 'Name',
        location: 'Locations',
        role: 'Role',
        type: 'Type',
        start: 'Start date',
        end: 'End date',
        skills: 'Skills',
        description: 'Description',
    },
    placeholders: {
        name: 'Name of the project',
        location: 'Type a location and press enter to add',
        role: 'Your role',
        skills: 'Type a skill and press enter to add',
        description: 'Describe the project',
    },

    helpText: {
        name: 'The company you worked for or the name of the project you worked on.',
        location: 'Where did you work on this project? You can add multiple locations.',
        role: 'What was your role in this project?',
        type: 'What kind of project was it?',
        start: 'When did you start working on this project?',
        end: 'When did the project end? Leave empty if it is still ongoing',
        skills:
            'What skills did you use or learn in this project? You can add multiple skills. Start typing the name of the skill and then press enter to add it to the list. You can also paste comma separated lists of skills.',
        description: 'Describe what you did in this project. How did you work? What were some of your achievements?',
    },
    new: function(userId, data) {
        return {
            ...pick(data, Project.editableFields),
            userId,
        };
    },
};
