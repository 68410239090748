import { pick } from 'lodash';

import { TextInput } from '../components/form/text-input';
import { trimWhiteSpace } from '../utils/string';

export const Skill = {
    editableFields: ['name'],
    fields: {
        name: TextInput,
    },
    validations: {
        name: {
            required: { presence: true },
            length: { max: 40 },
        },
    },
    required: {
        name: true,
    },
    placeholders: {
        name: 'Type skill name and press enter to add',
    },
    labels: {
        name: 'Skill Name',
    },
    new: function(userId, data) {
        return {
            ...pick(data, Skill.editableFields),
            userId,
        };
    },
    buildSkillId: (userId, skill) => {
        return [trimWhiteSpace(skill.name.toLowerCase()), userId].join('___');
    },
};
