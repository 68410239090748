import { isLoggedIn } from '../../utils/firebase';

export function currentUserSkillsSet(state, payload) {
    if (!isLoggedIn()) {
        return state;
    }

    state.currentUser.skills = payload;

    return state;
}
