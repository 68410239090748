export function currentUserProfileSet(state, payload) {
    const { currentUser } = state;

    if (!currentUser) {
        return state;
    }

    state.currentUser = {
        ...currentUser,
        ...payload,
    };

    return state;
}
