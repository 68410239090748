export function isPresent(value) {
    return (
        value !== undefined &&
        value !== null &&
        (typeof value === 'boolean' ||
            (typeof value === 'string' && value !== '') ||
            (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length > 0) ||
            (typeof value === 'object' && Array.isArray(value) && value.length > 0))
    );
}

export function hasArrayValues(data) {
    return Boolean(data && Array.isArray(data) && data.length > 0 && data.every(isPresent));
}

export function hasObjectValues(data) {
    return Boolean(
        data &&
            typeof data === 'object' &&
            !Array.isArray(data) &&
            Object.keys(data).length > 0 &&
            Object.values(data).filter(isPresent).length === Object.keys(data).length
    );
}

export function hasObjectKeyValues(data, keys) {
    if (!keys || !Array.isArray(keys) || keys.length === 0) {
        return hasObjectValues(data);
    }

    return Boolean(data && typeof data === 'object' && !Array.isArray(data) && Object.keys(data).length > 0 && keys.every(key => isPresent(data[key])));
}

export function buildValidationMessage(errors) {
    return Object.keys(errors)
        .map(key => [errors[key]].join(', '))
        .join(', ');
}
