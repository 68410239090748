import React, { useContext } from 'react';

import { Footer } from './footer';
import { Navigation } from './navigation';
import { Routing } from './routing';
import { ApplicationContext } from '../application/context';

export const Layout = () => {
    const { state } = useContext(ApplicationContext);
    const { currentUser } = state;

    return (
        <div className="skills">
            <Navigation currentUser={currentUser} />
            <Routing currentUser={currentUser} />
            <Footer />
        </div>
    );
};
