import React from 'react';
import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';
import { BrowserRouter } from 'react-router-dom';

import './index.scss';
import { App } from './app';

const root = document.getElementById('root');

loadableReady(() => {
    hydrate(
        <BrowserRouter>
            <App initialState={window.__INITIAL_STATE__ || {}} />
        </BrowserRouter>,
        root
    );
});

if (module.hot) {
    module.hot.accept();
}
