import React, { useContext, useEffect } from 'react';

import { Actions } from '../application/actions';
import { ApplicationContext } from '../application/context';
import { auth } from '../utils/firebase';

import { Layout } from './layout';

export const authHandler = async (user, dispatch) => {
    if (user) {
        const { claims } = await user.getIdTokenResult();
        user = { ...user, claims };
        dispatch({ type: Actions.currentUser.login, payload: user });
        dispatch({ type: Actions.currentUser.profile.load, payload: { user: user.uid, dispatch: dispatch } });
    } else {
        dispatch({ type: Actions.currentUser.logout });
    }
};

export const ApplicationContent = () => {
    const { dispatch } = useContext(ApplicationContext);

    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged(user => authHandler(user, dispatch));

        return () => unregisterAuthObserver();
        // eslint-disable-next-line
    }, []);

    return <Layout />;
};
