import React, { useContext } from 'react';

import { firebase } from '../utils/firebase';
import FirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { ApplicationContext } from '../application/context';

export const Login = () => {
    const { firebaseUiConfig } = useContext(ApplicationContext);

    return <FirebaseAuth uiConfig={firebaseUiConfig} firebaseAuth={firebase.auth()} />;
};
