import React, { useState, useContext } from 'react';
import { get } from 'lodash';
import classNames from 'classnames';

import { FormContext } from './form';
import { FieldErrorText } from '../field-error-text';
import { Label } from './label';
import { hasArrayValues } from '../../utils/validations/helpers';
import { SimpleTag } from '../tag';
import { Icon } from '../icon';

export const MultiSelect = ({ path, label, inputConverter, errorLabel, helpText, required, ...rest }) => {
    const [focused, setFocused] = useState(false);
    const [input, setInput] = useState('');

    const { state, errors, onChange, onBlur, markAsTouched, getValidationClasses } = useContext(FormContext);

    const items = get(state, path, []);
    const fieldErrors = get(errors, path);

    const validationClasses = getValidationClasses(path);
    const fieldClasses = classNames('field', validationClasses);
    const inputClasses = classNames('combobox', validationClasses, { 'is-focused': focused });

    function handleInput(e) {
        const input = inputConverter ? inputConverter(e.target.value) : e.target.value;
        setInput(input);
        markAsTouched(path);
    }

    function addItem(text) {
        const newItem = text.trim();

        if (!items.includes(newItem)) {
            const newItems = [...items, newItem];
            setInput('');
            onChange(path, newItems);
        }
    }

    function addItems(textArray) {
        if (!hasArrayValues(textArray)) {
            return null;
        }

        const newItems = textArray.reduce(
            (newItems, text) => {
                const newText = text.trim();
                if (newText.length > 1 && !newItems.includes(newText)) {
                    newItems.push(newText);
                }
                return newItems;
            },
            [...items]
        );

        setInput('');
        onChange(path, newItems);
    }

    function createNewItem(e) {
        addItem(input);
    }

    function deleteItem(text) {
        const rest = items.filter(itemText => itemText !== text);
        onChange(path, rest);
    }

    function deleteLastItem() {
        const rest = items.slice(0, -1);
        onChange(path, rest);
    }

    function handlePaste(e) {
        e.preventDefault();

        try {
            const pastedText = e.clipboardData.getData('text');
            if (!pastedText) {
                return null;
            }

            addItems(pastedText.split(','));
        } catch (e) {
            console.error(e.message);
        }
    }

    function handleKey(e) {
        switch (e.key) {
            case ',':
            case 'Enter':
                if (input.trim().length > 0) {
                    e.persist();
                    createNewItem(e);
                }
                e.preventDefault();
                break;
            case 'Backspace':
                if (input.length === 0) {
                    e.persist();
                    deleteLastItem(e);
                }
                break;
            default:
                break;
        }
    }

    function handleFocus(e) {
        setFocused(true);
    }

    function handleBlur(e) {
        setFocused(false);
        onBlur(path, items);
    }

    const inputName = [path, 'input'].join('___');

    return (
        <div className={fieldClasses}>
            <Label htmlFor={inputName} required={required} helpText={helpText}>
                {label}
            </Label>
            <div className={inputClasses}>
                {hasArrayValues(items) ? <MultiSelectItemList items={items} handleClick={deleteItem} /> : <EmptyMultiSelectIndicator />}
                <input
                    {...rest}
                    id={inputName}
                    type="text"
                    value={input}
                    onPaste={handlePaste}
                    onKeyDown={handleKey}
                    onChange={handleInput}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
            </div>
            <FieldErrorText errors={fieldErrors} errorLabel={errorLabel || label} />
        </div>
    );
};

export const MultiSelectItemList = ({ items, handleClick }) => {
    return items.map(item => <MultiSelectItem key={item} item={item} handleClick={handleClick} />);
};

export const MultiSelectItem = ({ item, handleClick }) => (
    <span className="tags has-addons">
        <span className="tag">{item}</span>
        <span className="tag is-delete" onClick={() => handleClick(item)} />
    </span>
);

export const EmptyMultiSelectIndicator = () => (
    <SimpleTag>
        [ <Icon icon="fa fa-ellipsis-h" /> ]
    </SimpleTag>
);
