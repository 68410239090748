import React from 'react';

import classNames from 'classnames';

export const Icon = ({ icon, color, ...props }) => {
    return (
        <span {...props} className={classNames('icon', color)}>
            <i className={icon} />
        </span>
    );
};
