import { Repository } from '../repository';
import {Actions} from '../actions';

export function profileLoad(state, payload) {
    const { dispatch, handle } = payload;

    Repository.profile.fullProfileByHandle(handle).then(profile => {
        if (!profile) {
            dispatch({ type: Actions.error.set, payload: { message: 'Profile not found' } });
        } else {
            dispatch({ type: Actions.profile.set, payload: profile });
        }
    });

    return state;
}

// this is for SSR where we can wait for the data:
export async function asyncProfileLoad(state, payload) {
    const profile = await Repository.profile.fullProfileByHandle(payload);

    if (!profile) {
        state.error = { type: 404, message: 'Profile not found' };
    } else {
        state.currentProfile = profile;
        state.error = undefined;
    }

    return state;
}
