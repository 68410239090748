const pathToRegexp = require('path-to-regexp');

export class Sitemap {
    constructor(config) {
        this.pages = Object.keys(config).map(route => {
            const keys = [];
            const regex = pathToRegexp(route, keys);

            const getParams = url => {
                const result = regex.exec(url) || [];
                return keys.reduce((params, key, i) => ({ ...params, [key.name]: result[i + 1] }), {});
            };

            return {
                ...config[route],
                route,
                regex,
                keys,
                getParams,
            };
        }, []);
    }

    async prefetch(url) {
        const page = this.pages.find(({ regex }) => Boolean(regex.exec(url)));

        if (!page) {
            return {};
        }

        const { getParams, prefetch } = page;

        if (!getParams || !prefetch) {
            return {};
        }

        return prefetch(getParams(url));
    }
}
