import { get } from 'lodash';

import { requiredValidator } from './required-validator';
import { equalityValidator } from './equality-validator';
import { lengthValidator } from './length-validator';
import { matchValidator } from './match-validator';
import { hasArrayValues, hasObjectValues } from './helpers';

export const EmailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const Validations = {
    required: requiredValidator,
    equals: equalityValidator,
    length: lengthValidator,
    match: matchValidator,
};

export function hasErrors(validationResult) {
    return hasArrayValues(validationResult);
}

export function validate(value, config) {
    if (!config) {
        return {};
    }

    return Object.keys(Validations).reduce((errors, key) => {
        const validatorConfig = config[key];
        const validator = Validations[key];

        if (!validatorConfig || !validator) {
            return errors;
        }

        // execute the validator:
        const validationResult = validator(value, validatorConfig);

        if (hasErrors(validationResult)) {
            errors[key] = validationResult;
        }

        return errors;
    }, {});
}

export function validateObject(object, config) {
    return Object.keys(config).reduce((errors, path) => {
        const validatorConfig = config[path];
        const value = get(object, path);

        const validationResult = validate(value, validatorConfig);
        if (hasObjectValues(validationResult)) {
            errors[path] = validationResult;
        }

        return errors;
    }, {});
}

export function isObjectValid(object, config) {
    const validationResult = validateObject(object, config);
    return !hasObjectValues(validationResult);
}
