import { Repository } from '../repository';
import { isLoggedIn } from '../../utils/firebase';
import { Endorsement } from '../../models/endorsement';

export function isDataValid(state, payload) {
    const { resourceType, resourceId, resourceName, resourceOwnerId, endorsement } = payload;
    return (
        isLoggedIn() &&
        Boolean(resourceType) &&
        Boolean(resourceId) &&
        Boolean(resourceOwnerId) &&
        Boolean(resourceName) &&
        Boolean(endorsement) &&
        Endorsement.allowedTypes.includes(resourceType)
    );
}

export function endorsementsAdd(state, payload) {
    if (!isDataValid(state, payload)) {
        return state;
    }

    const { currentUser } = state;

    if (!currentUser) {
        return state;
    }

    Repository.endorsements.add({ ...payload, user: currentUser });

    return state;
}
