import { Actions } from './actions';

import { Reducer } from '../utils/reducer';

import { currentUserEndorsementsSet } from './actions/current-user-endorsements-set';
import { currentUserLogin } from './actions/current-user-login';
import { currentUserLogout } from './actions/current-user-logout';
import { currentUserProfileLoad } from './actions/current-user-profile-load';
import { currentUserProfileSet } from './actions/current-user-profile-set';
import { currentUserProjectsAdd } from './actions/current-user-projects-add';
import { currentUserProjectsDelete } from './actions/current-user-projecs-delete';
import { currentUserProjectsSet } from './actions/current-user-projects-set';
import { currentUserProjectsUpdate } from './actions/current-user-projects-update';
import { currentUserPublicProfileSet } from './actions/current-user-public-profile-set';
import { currentUserPublicProfileUpdate } from './actions/current-user-public-profile-update';
import { currentUserServicesAdd } from './actions/current-user-services-add';
import { currentUserServicesDelete } from './actions/current-user-services-delete';
import { currentUserServicesSet } from './actions/current-user-services-set';
import { currentUserServicesUpdate } from './actions/current-user-services-udpate';
import { currentUserSkillsAdd } from './actions/current-user-skills-add';
import { currentUserSkillsDelete } from './actions/current-user-skills-delete';
import { currentUserSkillsSet } from './actions/current-user-skills-set';
import { endorsementsAdd } from './actions/endorsements-add';
import { errorSet } from './actions/error-set';
import { modalHide } from './actions/modal-hide';
import { modalShow } from './actions/modal-show';
import { profileLoad } from './actions/profile-load';
import { profileSet } from './actions/profile-set';

export const ReducerActions = {
    [Actions.currentUser.endorsements.set]: currentUserEndorsementsSet,
    [Actions.currentUser.login]: currentUserLogin,
    [Actions.currentUser.logout]: currentUserLogout,
    [Actions.currentUser.profile.load]: currentUserProfileLoad,
    [Actions.currentUser.profile.set]: currentUserProfileSet,
    [Actions.currentUser.projects.add]: currentUserProjectsAdd,
    [Actions.currentUser.projects.delete]: currentUserProjectsDelete,
    [Actions.currentUser.projects.set]: currentUserProjectsSet,
    [Actions.currentUser.projects.update]: currentUserProjectsUpdate,
    [Actions.currentUser.publicProfile.set]: currentUserPublicProfileSet,
    [Actions.currentUser.publicProfile.update]: currentUserPublicProfileUpdate,
    [Actions.currentUser.services.add]: currentUserServicesAdd,
    [Actions.currentUser.services.delete]: currentUserServicesDelete,
    [Actions.currentUser.services.set]: currentUserServicesSet,
    [Actions.currentUser.services.update]: currentUserServicesUpdate,
    [Actions.currentUser.skills.add]: currentUserSkillsAdd,
    [Actions.currentUser.skills.delete]: currentUserSkillsDelete,
    [Actions.currentUser.skills.set]: currentUserSkillsSet,
    [Actions.endorsements.add]: endorsementsAdd,
    [Actions.error.set]: errorSet,
    [Actions.modal.hide]: modalHide,
    [Actions.modal.show]: modalShow,
    [Actions.profile.load]: profileLoad,
    [Actions.profile.set]: profileSet,
};

export const AfterActionHooks = {};

export const ApplicationReducer = (state, action) => {
    return Reducer(state, action, ReducerActions, AfterActionHooks);
};
