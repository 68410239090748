import React from 'react';

export const Spinner = () => (
    <div className="loading-indicator">
        <span className="loader" />
        <div className="loading-text">
            <span role="img" aria-label="thinking-emoji">
                🤔
            </span>
            &nbsp;
            Loading ...
        </div>
    </div>
);

export const LoadingIndicator = ({ children, isLoading }) => {
    return isLoading ? <Spinner /> : children;
};
