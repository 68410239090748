export function currentUserLogin(state, payload) {
    if (!payload) {
        return state;
    }

    const { email, displayName: name, photoURL: avatar, uid, claims } = payload;

    const userClaims = claims ? { isAdmin: claims.isAdmin, isModerator: claims.isModerator || false } : { isAdmin: false, isModerator: false };

    state.currentUser = {
        uid,
        claims: userClaims,
        email,
        name,
        avatar,
    };

    return state;
}
