import { Repository } from '../repository';
import { isLoggedIn } from '../../utils/firebase';
import { get } from 'lodash';
import { cleanHandle } from '../../utils/string';

export function isDataValid(state, payload) {
    return isLoggedIn() && get(payload, 'handle');
}

export function currentUserPublicProfileUpdate(state, payload) {
    if (!isDataValid(state, payload)) {
        return state;
    }

    const { currentUser } = state;

    const cleanedPayload = {
        ...payload,
        handle: cleanHandle(payload.handle),
    };

    Repository.profile.update(currentUser.uid, cleanedPayload);

    state.modal = undefined;

    return state;
}
