export function modalShow(state, payload) {
    if (!payload) {
        return state;
    }

    const { modal, context } = payload;

    state.modal = modal;
    state.modalContext = context;

    document.documentElement.classList.add('is-clipped');

    return state;
}
