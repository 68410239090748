import React, { useContext } from 'react';

import { cloneDeep, get, set } from 'lodash';
import { Info, DateTime } from 'luxon';
import classNames from 'classnames';

import { FormContext } from './form';
import { Label } from './label';
import { FieldErrorText } from '../field-error-text';

const pastYears = 60;
const futureYears = 3;
const currentYear = new Date().getFullYear();

const years = [];
for (let i = currentYear + futureYears; i > currentYear - pastYears; i--) {
    years.push(i);
}

export const DatePicker = ({ path, label, className, required, errorLabel, helpText, ...rest }) => {
    const { state, onChange, onBlur, errors, getValidationClasses } = useContext(FormContext);
    const value = get(state, path, {});

    const yearName = `${path}.year`;
    const monthName = `${path}.month`;
    const dayName = `${path}.day`;

    function handleChange(part, inputValue) {
        const newValue = cloneDeep(value);
        set(newValue, part, inputValue);
        onChange(path, newValue);
    }

    function handleBlur(part, inputValue) {
        const newValue = cloneDeep(value);
        set(newValue, part, inputValue);
        onBlur(path, newValue);
    }

    const fieldErrors = get(errors, path);

    const validationClasses = getValidationClasses(path);
    const fieldClasses = classNames('field', validationClasses);
    const inputClasses = classNames('datepicker-fields', validationClasses);

    return (
        <div className={fieldClasses}>
            <Label required={required} htmlFor={path} helpText={helpText}>
                {label}
            </Label>
            <div className={inputClasses}>
                <div className="select">
                    <select
                        {...rest}
                        name={yearName}
                        id={yearName}
                        value={(value && value.year) || ''}
                        onChange={e => handleChange('year', e.target.value)}
                        onBlur={e => handleBlur('year', e.target.value)}>
                        <option key="blank" value="">
                            Year
                        </option>
                        <YearSelectOptions />
                    </select>
                </div>
                <div className="select">
                    <select
                        {...rest}
                        name={monthName}
                        id={monthName}
                        value={(value && value.month) || ''}
                        onChange={e => handleChange('month', e.target.value)}
                        onBlur={e => handleBlur('month', e.target.value)}>
                        <option key="blank" value="">
                            Month
                        </option>
                        <MonthSelectOptions />
                    </select>
                </div>
                <div className="select">
                    <select
                        {...rest}
                        name={dayName}
                        id={dayName}
                        disabled={!Boolean(value.year) || !Boolean(value.month)}
                        value={(value && value.day) || ''}
                        onChange={e => handleChange('day', e.target.value)}
                        onBlur={e => handleBlur('day', e.target.value)}>
                        <option key="blank" value="">
                            Day
                        </option>
                        <DaySelectOptions year={value && value.year} month={value && value.month} />
                    </select>
                </div>
            </div>
            <FieldErrorText errors={fieldErrors} errorLabel={errorLabel || label} />
        </div>
    );
};

export const YearSelectOptions = () => {
    return years.map(year => (
        <option key={year} value={year}>
            {year}
        </option>
    ));
};

export const MonthSelectOptions = () => {
    return Info.months().map((month, index) => (
        <option key={month} value={index + 1}>
            {month}
        </option>
    ));
};

export const DaySelectOptions = ({ year, month }) => {
    if (!Boolean(year) || !Boolean(month)) {
        return null;
    }

    const lastDay = DateTime.fromObject({ year, month }).daysInMonth;
    const days = [];

    for (let i = lastDay; i > 0; i--) {
        days.push(
            <option key={i} value={i}>
                {i}
            </option>
        );
    }

    return days;
};
