import { hasArrayValues, hasObjectKeyValues, hasObjectValues } from './helpers';

export const defaultMessage = 'is required';

export const defaultConfig = {
    message: defaultMessage,
    presence: true,
};

export function requiredValidator(value, config = defaultConfig) {
    const errors = [];

    if (!config) {
        return errors;
    }

    const { message, presence } = config;

    if (presence !== true) {
        return errors;
    }

    const errorMessage = message || defaultMessage;

    if (value === null || value === undefined) {
        errors.push(errorMessage);
        return errors;
    }

    // eslint-disable-next-line default-case
    switch (typeof value) {
        case 'string':
            if (value.length === 0) {
                errors.push(errorMessage);
            }
            break;
        case 'object':
            if (Array.isArray(value)) {
                if (!hasArrayValues(value)) {
                    errors.push(errorMessage);
                }
            } else {
                const requiredKeys = Array.isArray(config.keys) && config.keys;

                if (!requiredKeys) {
                    if (!hasObjectValues(value)) {
                        errors.push(errorMessage);
                    }
                } else {
                    if (!hasObjectKeyValues(value, config.keys)) {
                        errors.push(errorMessage);
                    }
                }
            }
            break;
    }

    return errors;
}
