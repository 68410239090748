import { isLoggedIn } from "../../utils/firebase";

export function currentUserServicesSet(state, payload) {
    if (!isLoggedIn()) {
        return state;
    }

    state.currentUser.services = payload;

    return state;
}
