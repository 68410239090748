import { get } from 'lodash';

import { Repository } from '../repository';
import { isLoggedIn } from "../../utils/firebase";

export function isDataValid(state, payload) {
    return isLoggedIn() && get(payload, 'uid') && get(payload, 'form');
}

export function currentUserServicesUpdate(state, payload) {
    if (!isDataValid(state, payload)) {
        return state;
    }

    const { currentUser } = state;

    Repository.services.update(currentUser.uid, payload.uid, payload.form);
    state.editingService = undefined;

    return state;
}
