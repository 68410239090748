export function cleanHandle(value) {
    return (value || '')
        .trim() // remove whitespace from beginning and end of string
        .replace(/[^a-z0-9-_]/gi, '') // remove non allowed characters
        .replace(/([-_])\1+/gi, '$1') // remove repeating - or _
        .replace(/^([-_]+)/gi, '') // remove - or _ from beginning of string
        .replace(/([-_])+$/gi, '') // remove - or _ from end of string
        .toLowerCase();
}

export function trimWhiteSpace(value) {
    return (value || '')
        .trim()
        .replace(/[^-a-z0-9#_.]/gi, '') // remove non allowed characters
        .replace(/([-_.])\1+/gi, '$1') // remove repeating - or _
        .replace(/^([-_.]+)/gi, '') // remove - or _ from beginning of string
        .replace(/([-_.])+$/gi, '') // remove - or _ from end of string
}
