import { pick, get } from 'lodash';

export const EndorsementTypeNameMapping = {
    skill: 'Skill',
};

export const Endorsement = {
    allowedTypes: ['skill', 'profile', 'project'],
    allowedAttributes: ['comment'],

    new: function({ resourceType, resourceId, resourceName, resourceOwnerId, user, endorsement }) {
        return {
            ...pick(endorsement, Endorsement.allowedAttributes),
            resourceType,
            resourceId,
            resourceName,
            resourceOwnerId,
            userId: user.uid,
            user: { handle: get(user, 'profile.handle', 'Anonymous') },
        };
    },
};
