export const Actions = {
    profile: {
        load: 'PROFILE.LOAD',
        set: 'PROFILE.SET'
    },
    error: {
        set: 'ERROR.SET',
    },
    currentUser: {
        login: 'CURRENT_USER.LOGIN',
        logout: 'CURRENT_USER.LOGOUT',
        publicProfile: {
            set: 'CURRENT_USER.PUBLIC_PROFILE.SET',
            update: 'CURRENT_USER.PUBLIC_PROFILE.UPDATE',
        },
        profile: {
            load: 'CURRENT_USER.PROFILE.LOAD',
            set: 'CURRENT_USER.PROFILE.SET',
        },
        skills: {
            add: 'CURRENT_USER.SKILLS.ADD',
            delete: 'CURRENT_USER.SKILLS.DELETE',
            set: 'CURRENT_USER.SKILLS.SET',
        },
        projects: {
            add: 'CURRENT_USER.PROJECTS.ADD',
            delete: 'CURRENT_USER.PROJECTS.DELETE',
            set: 'CURRENT_USER.PROJECTS.SET',
            update: 'CURRENT_USER.PROJECTS.UPDATE',
        },
        services: {
            add: 'CURRENT_USER.SERVICES.ADD',
            delete: 'CURRENT_USER.SERVICES.DELETE',
            set: 'CURRENT_USER.SERVICES.SET',
            update: 'CURRENT_USER.SERVICES.UPDATE',
        },
        endorsements: {
            set: 'CURRENT_USER.ENDORSEMENTS.SET',
        },
    },
    endorsements: {
        add: 'ENDORSEMENTS.ADD',
    },

    modal: {
        hide: 'MODALS.HIDE',
        show: 'MODALS.SHOW',
    },
};
