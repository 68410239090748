import React, { useEffect, useContext } from 'react';
import useRouter from 'use-react-router';

import { ApplicationContext } from '../application/context';
import { Actions } from '../application/actions';

import { SkillsSitemapComponent } from '../sitemap-config';

export const Routing = () => {
    const { dispatch } = useContext(ApplicationContext);
    const { history, location } = useRouter();

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            dispatch({ type: Actions.error.set, payload: undefined });
        });

        return () => {
            unlisten();
        };
        // eslint-disable-next-line
    }, [location.url]);

    useEffect(() => {
        document.documentElement.classList.remove('is-hidden');
    }, []);

    return (
        <div className="main-content">
            <SkillsSitemapComponent />
        </div>
    );
};
