import React from 'react';

import { buildValidationMessage, hasObjectValues } from '../utils/validations/helpers';

export const FieldErrorText = ({ errors, errorLabel }) => {
    if (!hasObjectValues(errors)) {
        return <p className="help">&nbsp;</p>
    }

    const errorText = buildValidationMessage(errors);

    return <p className="help is-danger">{errorLabel}: {errorText}</p>;
};
