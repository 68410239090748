import React from 'react';

import { Link } from 'react-router-dom';

import { UserPicture } from "../../components/user-picture";

export const Avatar = ({ currentUser }) => {
    if (!currentUser) {
        return null;
    }
    const { avatar } = currentUser;

    if (!avatar) {
        return null;
    }

    return (
        <Link to="/my-profile" className="navigation-profile-link" title="view your profile">
            <UserPicture avatar={avatar} />
        </Link>
    );
};

