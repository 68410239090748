/*eslint no-console: ["error", { allow: ["error", "info", "log"] }] */

import { cloneDeep } from 'lodash';
import { getEnv } from './env';

export function runAfterActionHooks(hooks, state, logger = console, env = getEnv()) {
    if (!hooks) {
        return state;
    }

    if (env === 'development') {
        logger.info('executing after action hooks');
    }

    return hooks.reduce((hookState, hook) => {
        return hook(cloneDeep(hookState));
    }, cloneDeep(state));
}

export async function runAsyncAfterActionHooks(hooks, state, logger = console, env = getEnv()) {
    if (!hooks) {
        return null;
    }

    if (env === 'development') {
        logger.info('executing after async action hooks');
    }

    hooks.forEach(hook => {
        return hook(cloneDeep(state));
    }, state);
}

export const Reducer = (state, action, reducerActions = {}, afterActionHooks = {}, asyncAfterActionHooks = {}, logger = console, env = getEnv()) => {
    if (!state) {
        throw new Error('Reducer called without state.');
    }

    if (!action) {
        throw new Error('Reducer called without action.');
    }

    const { type, payload } = action;

    if (!type) {
        throw new Error('Action has no type');
    }

    const reducerAction = reducerActions[type];

    if (!reducerAction) {
        if (env === 'development') {
            logger.info('Action fell through: ', action);
        }

        return state;
    }

    if (env === 'development') {
        logger.info('executing Action: ', type, payload);
    }
    const newState = reducerAction(cloneDeep(state), payload, logger);

    if (env === 'development') {
        logger.info('new state: ', newState);
    }

    const afterHooksState = runAfterActionHooks(afterActionHooks[type], newState, logger);

    runAsyncAfterActionHooks(asyncAfterActionHooks[type], afterHooksState, logger)
        .then(() => {
            if (env === 'development') {
                logger.info('finished async hooks');
            }
        })
        .catch(err => logger.error(`error running async hooks: ${err}`));

    return afterHooksState;
};
