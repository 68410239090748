import React from 'react';
import { Link } from 'react-router-dom';

import { get } from 'lodash';

import logo from '../assets/skills-logo.svg';

import { Authentication } from './components/authentication';
import { Avatar } from './components/avatar';
import { Icon } from '../components/icon';

export const Navigation = ({ currentUser }) => {
    return (
        <div className="section navigation">
            <div className="container">
                <div className="navigation-logo">
                    <Link to="/">
                        <img src={logo} alt="" />
                    </Link>
                </div>
                <div className="navigation-settings">
                    <RoleIndicators currentUser={currentUser} />

                    <Avatar currentUser={currentUser} />
                    <Authentication currentUser={currentUser} />
                </div>
            </div>
        </div>
    );
};

export const RoleIndicators = ({ currentUser }) => {
    const isAdmin = get(currentUser, 'claims.isAdmin', false);
    const isModerator = get(currentUser, 'claims.isModerator', false);

    if (!isAdmin && !isModerator) {
        return null;
    }

    return (
        <div className="role-indicators">
            {isAdmin && <Icon icon="fa fa-user-crown" title="Admin" />}
            {isModerator && <Icon icon="fa fa-user-shield" title="Moderator" />}
        </div>
    );
};
