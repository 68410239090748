import React from 'react';

import { Login } from './login';

export const LoginNotice = () => {
    return (
        <div className="login-notice">
            <section className="section">
                <h1 className="title has-text-centered">You need to be logged in to access this page</h1>
            </section>

            <section className="section has-text-centered">
                <Login />
            </section>
        </div>
    );
};
