import React, { useContext } from 'react';

import { get } from 'lodash';
import classNames from 'classnames';

import { FormContext } from './form';
import { FieldErrorText } from '../field-error-text';
import { Label } from './label';

export const Select = ({ path, label, className, required, errorLabel, helpText, children, ...rest }) => {
    const { state, errors, onChange, onBlur, getValidationClasses } = useContext(FormContext);
    const value = get(state, path, '');

    const fieldErrors = get(errors, path);

    const validationClasses = getValidationClasses(path);
    const fieldClasses = classNames('field', validationClasses);
    const inputClasses = classNames('select', validationClasses);

    return (
        <div className={fieldClasses}>
            <Label required={required} htmlFor={path} helpText={helpText}>
                {label}
            </Label>
            <div className={inputClasses}>
                <select {...rest} name={path} id={path} value={value} onChange={e => onChange(path, e.target.value)} onBlur={e => onBlur(path, e.target.value)}>
                    {children}
                </select>
            </div>
            <FieldErrorText errors={fieldErrors} errorLabel={errorLabel || label} />
        </div>
    );
};
