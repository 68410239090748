import { get } from 'lodash';

import { Repository } from '../repository';
import { isLoggedIn } from "../../utils/firebase";

export function isDataValid(state, payload) {
    return isLoggedIn() && get(payload, 'name');
}

export function currentUserProjectsAdd(state, payload) {
    if (!isDataValid(state, payload)) {
        return state;
    }

    const { currentUser } = state;

    Repository.projects.add(currentUser.uid, payload);

    return state;
}
