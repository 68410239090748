import _firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

export const firebaseConfig = {
    apiKey: 'AIzaSyBYWy-Eji3NTyGx3V2RYNP2kkO4DgpR7eA',
    authDomain: 'skills-56939.firebaseapp.com',
    databaseURL: 'https://skills-56939.firebaseio.com',
    projectId: 'skills-56939',
    storageBucket: 'skills-56939.appspot.com',
    messagingSenderId: '839630764203',
    appId: '1:839630764203:web:645a2b8360c45ee1',
};

_firebase.initializeApp(firebaseConfig);

export const firebase = _firebase;
export const auth = firebase.auth();
export const db = firebase.firestore();

export function currentUser() {
    return auth.currentUser;
}

export function isLoggedIn() {
    return Boolean(auth.currentUser);
}

export function logoutUser() {
    auth.signOut();
}

export function addUidToDocument(doc) {
    return {
        uid: doc.id,
        ...doc.data(),
    };
}
